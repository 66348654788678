@use "/src/utilities/var";

$bg-color-green: #66bb6a;
$bg-color-yellow: #cf8311;
$bg-color-red: red;

.phover:hover {
  cursor: pointer;
  font-weight: bold;
}

.percentageBar {
  width: 90%;
  height: 6px;
  display: flex;
  border-radius: 3px;
  overflow: hidden;

  .onSchedule,
  .slightlyBehind,
  .significantlyBehind {
    height: 8px;
  }

  .onSchedule {
    background-color: $bg-color-green;
  }

  .slightlyBehind {
    background-color: $bg-color-yellow;
  }

  .significantlyBehind {
    background-color: $bg-color-red;
  }
}