.container {
  width: 480px;
  /* height: fit-content; */
  height: 50vh;
  /* overflow: auto; */
  background: #00212F;
  position: absolute;
  top: 70px;
  right: 144px;
  border-radius: 6px;
  z-index: 20;
}

.notificationCounter {
  position: absolute;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  top: 0px;
  right: 0px;
  transform: translate(40%, -40%);
  z-index: 10px;
  background-color: var(--action);
  color: #fff;
}