:root {
  --wed-blue: #003D55;
  --primary: white;
  --secondary: #132f39;
  --action: #BF9731;
  /* --- WED Components Colors ---- */
  --color-green: #7aca93;
  --color-red: #fa7c5f;
  --color-blue: #508db6;
  --color-yellow: #ffbd59;
  --color-magenta: #ca7a92;
  --color-greenBlue: #4db3b3;
  --color-strongRed: #cc3333;
  --color-strongYellow: #f2c40d;
  --color-darkBlue: #107dac;
  --color-grayBlue: #587274;
  --color-darkGreen: #006633;
  --color-darkPink: #be3475;
  --color-lightPurple: #ab59b0;
  --color-darkPurple: #420d4b;
  --color-darkRed: #900b22;
  --color-orange: #da6e2e;
  --color-darkYellow: #bf9f00;
  --color-strongBlue: #6396ee;
  --color-text-subtitle: #bbb;
  --color-text-disabled: #888;
  /* Toastify */
  --toastify-color-dark: #444;
}

.App {
  display: flex;
  height: 100vh;
}

.App .view {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  flex-grow: 1;
}

a {
  text-decoration: none;
  color: var(--secondary);
}

.scrollbar-styling::-webkit-scrollbar {
  background-color: var(--scrollbar-bg-color);
  width: 8px;
}

.scrollbar-styling::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: var(--border-radius);
}

.scrollbar-styling::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color);
}

.textEllipsisOnOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}