@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

.tableContainer {
  /* Dimensions */
  --overhead-height: 90px;
  --body-height: 45px;
  --border-radius: 4px;
  --header-height: 48;
  --cell-height: 36px;
  --cell-padding: 0.5rem;
  --table-max-height: calc(100vh - 215px);

  /* Colors */
  --overhead-bg-color: #00151e;
  --header-bg-color: #00212f;
  --body-bg-color-darkest: #00141b;
  --body-bg-color-darker: #00202c;
  --body-bg-color-dark: #003144;
  --body-bg-color-light: #002b3b;
  --body-bg-hover: rgba(0, 0, 0, 0.1);
  --body-bg-selected: rgba(255, 255, 255, 0.1);
  --body-bg-active: rgba(0, 0, 0, 0.025);
  --divider-color: rgba(255, 255, 255, 0.8);
  --font-color: white;
  --gross-value: gray;
  --paid-value: #a2e6a5;
  --different-paid-value: #cf7f7f;
  --null-paid-value: #e6dfa2;

  /* Scrollbar */
  --scrollbar-bg-color: rgba(0, 0, 0, 0.1);
  --scrollbar-thumb-color: rgba(255, 255, 255, 0.25);
  --scrollbar-thumb-hover-color: rgba(255, 255, 255, 0.4);

  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: center;
  width: max-content;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  color: var(--font-color);
}

.independentGridItem {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.fixedContainer {
  /* background-color: aquamarine; */
  flex-shrink: 0;
  max-height: var(--table-max-height);
  overflow-y: auto;
  position: relative;
  border-right: 4px double white;
}

/* Remove scrollbar from fized columns */
.fixedContainer::-webkit-scrollbar {
  width: 0px;
}

.scrollContainer {
  /* background-color: aqua; */
  width: auto;
  max-height: var(--table-max-height);
  overflow: auto;
}

/* width */
.scrollContainer::-webkit-scrollbar {
  background-color: var(--scrollbar-bg-color);
  width: 8px;
  height: 8px;
}

/* Track */
.scrollContainer::-webkit-scrollbar-track {
  background: var(--header-bg-color);
}

/* Corner */
.scrollContainer::-webkit-scrollbar-corner {
  background: var(--header-bg-color);
}

/* Handle */
.scrollContainer::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: var(--border-radius);
}

/* Handle on hover */
.scrollContainer::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color);
}

.notFixedColumns {
  background-color: blueviolet;
  overflow-x: auto;
  display: flex;
}

.headerRow {
  display: flex;
  font-weight: 500;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  overflow-x: auto;
  z-index: 2;
}

.headerRow::-webkit-scrollbar {
  background-color: var(--scrollbar-bg-color);
  height: 0px;
}

.notFixedRows {
  background-color: burlywood;
  overflow-x: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  width: fit-content;
  min-height: var(--cell-height);
  position: relative;

  &.total {
    width: 100%;
  }

  &.lastBundledRow::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: white;
  }

  // &:has(div.bundle:active) :is(.cell) {
  //   background-color: #bbb;
  // }

  &.selectedRow :is(.cell, .cell.bundle) {
    background-color: var(--body-bg-selected);
  }
}

.row:first-of-type {
  margin-top: 60px;
}

.column {
  background-color: rgba(0, 0, 0, 0.3);
  height: 212px;
  min-width: 100px;
  margin-right: 1rem;
}

.cell {
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
  padding: var(--cell-padding);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  height: var(--cell-height);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.even {
    background-color: var(--body-bg-color-dark);
  }

  &.odd {
    background-color: var(--body-bg-color-light);
  }

  &.bundle.even {
    background-color: var(--body-bg-color-darker);
    font-weight: 700;
  }

  &.bundle.odd {
    background-color: var(--body-bg-color-darkest);
    font-weight: 700;
  }

  &.selectedRow {
    background-color: var(--body-bg-selected);
  }

  &.numberCell {
    width: 100%;
    text-align: end;
  }

  &.paidValue {
    color: var(--paid-value);
  }

  &.differentPaidValue {
    color: var(--different-paid-value);
  }

  &.nullPaidValue {
    color: var(--null-paid-value);
  }

  &.grossValue {
    color: var(--gross-value);
  }

  &.beneficiaryName:hover {
    font-weight: bold;
  }

  &.withComment::after {
    content: "!";
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 4px;
    left: 4px;
    color: var(--body-bg-color-dark);
    background-color: var(--null-paid-value);
    font-size: 12px;
    font-weight: 900;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  &>span.statusCell {
    display: inline-block;
    box-sizing: border-box;
    width: 90px;
    border-radius: 8px;
    text-align: center;
    padding-inline: .5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.bgGreen {
      background-color: #006633;
    }

    &.bgRed {
      background-color: #900b22;
    }

    &.bgBlue {
      background-color: #6396ee;
    }

    &.bgYellow {
      background-color: #c2a100;
    }
  }
}

.headerCell {
  background-color: var(--header-bg-color);
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  min-height: 48px;
  display: flex;
  align-items: center;
}

.monthHeaderCell {
  cursor: pointer;

  &:hover {
    // font-weight: bold;

    &::after {
      content: '+';
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      text-align: center;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.4);
      color: #00141b;
      font-weight: 800;
      margin-left: .5rem;
    }
  }
}

.popUpCell {
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  top: 0;
  border-right: 2px solid white;
  min-height: var(--cell-height);
  padding: var(--cell-padding);
  background-color: var(--overhead-bg-color);
}

.pointer:hover {
  cursor: pointer;
  font-weight: bold;
}

.cellComment {
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  top: 0;
  transform: translateY(var(--cell-height));
  border: 2px solid white;
  min-height: var(--cell-height);
  max-width: 220px;
  padding: var(--cell-padding);
  background-color: var(--overhead-bg-color);
}

.expandButtonContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  $button-width-height: 100%;
  $bg-color: rgba(0, 0, 0, 0.25);

  button {
    background-color: $bg-color;
    width: $button-width-height;
    height: $button-width-height;

    svg {
      color: white;
    }

    &:hover {
      background-color: darken($bg-color, 30%);
    }
  }
}

.bulkUpdateModal {
  background-color: #121212;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 25vh;
  width: 50%;
}