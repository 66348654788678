.legendContainer {
  margin-top: 12px;
  color: white;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
}

.count {
  display: flex;
  align-items: center;
}

.legend {
  display: flex;
  gap: 8px;
  font-size: 1rem;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 8px;
  border-radius: 4px;
}

.legend li {
  list-style-type: none;
  padding: 4px 8px;
  border-radius: 4px;
}

.red {
  background-color: var(--color-red);
}

.yellow {
  background-color: var(--color-yellow);
}

.greenBlue {
  background-color: var(--color-greenBlue);
}

.green {
  background-color: var(--color-green);
}
