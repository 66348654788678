.nav {
  background-color: var(--primary);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  flex-shrink: 0;
}

.retracted {
  transition: 0.1s;
  width: 64px;
}

.expanded {
  transition: 0.2s;
  width: 240px;
}

button {
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

button.expandButton {
  width: 64px;
  height: 64px;
  padding: 8px;
  color: var(--secondary);
}

button.navButton {
  width: 300px;
  height: 32px;
  color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}

button .notSelected {
  width: 8px;
  background-color: transparent;
  transition: 0.25s;
}

button .selected {
  width: 8px;
  height: 100%;
  background-color: var(--action);
}

nav button .icon {
  position: relative;
  height: 70%;
  width: 56px;
  margin: 0px;
}

nav button .icon .notification {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 16px;
  background-color: #FA7C5F;
  color: white;
  font-weight: bolder;
  font-size: 12px;
  display: grid;
  place-items: center;
}

nav button .label {
  text-align: left;
  flex-grow: 1;
  font-size: 13px;
}

.navSectionTitle {
  height: 32px;
  background-color: rgba(190, 156, 93, .75);
  width: 100%;
  padding-left: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--secondary);
  display: flex;
  align-items: center;
}

.navSectionTitle:not(:first-of-type) {
  margin-top: .5rem;
}