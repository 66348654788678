.htmlContentBox {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: transparent;
    height: 100%;
    max-height: 72.5vh;
    overflow: auto;
}

/* Estilizando a scrollbar */
.htmlContentBox::-webkit-scrollbar {
    width: 8px;
}

.htmlContentBox::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 4px;
}

.htmlContentBox::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.htmlContentBox::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.customInputWithScrollbar::-webkit-scrollbar {
    width: 8px;
}

/* .customInputWithScrollbarinput::-webkit-scrollbar-track {
    background-color: #ae2222;
    border-radius: 4px;
} */

.customInputWithScrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.customInputWithScrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}