select.selectStatus {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 4px;
  color: white;
}

.selectStatus > option {
  height: 38px;
}

.underRevision {
  background-color: #508DB6;
}
.canceled {
  background-color: #FFBD59;
}
.approved {
  background-color: #7ACA93;
}
.rejected {
  background-color: #FA7C5F;
}

.quickDateBtn {
  background-color: white;
  border-radius: 4px;
  color: var(--secondary);
  height: 45%;
  cursor: pointer;
  transition: 250ms all ease;
}

.quickDateBtn:hover {
  background-color: rgb(200, 200, 200);
}