.home {
  color: white;
  padding: 20px;

}

.anim {
  width: 300px;
  height: 300px;
  background-color: white;
}

.anim svg {
  width: 100%;
  height: 100%;
}