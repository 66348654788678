:root {
  --height: 100;
}

.container {
  background-color: var(--action);
  color: var(--primary);
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 250px;
  height: 100px;
  padding: 0px 25px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  place-items: center;
  transition: .5s;
  z-index: 4;
}

.hidden {
  right: -300px;
}

.shown {
  right: 20px;
}

.message {
  font-size: 20px;
}